import * as React from 'react';
import { Link, Typography, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const linkMap = {
  de: {
    imprint: 'https://flowlab.com/impressum/',
    privacyPolicy: 'https://flowlab.com/datenschutz/',
    toc: 'https://flowlab.com/agb/',
  },
  en: {
    imprint: 'https://flowlab.com/en/imprint/',
    privacyPolicy: 'https://flowlab.com/en/privacy-policy/',
    toc: 'https://flowlab.com/en/terms-and-conditions/',
  },
};

export function PageFooter() {
  const { t, i18n } = useTranslation();

  const lang = i18n.language.startsWith('de') ? 'de' : 'en';

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      style={{ width: '100%', backgroundColor: '#262626' }}
    >
      <Box display="flex" my={3}>
        <Box mx={1}>
          <Link style={{ color: '#636363' }} variant="body2" href={linkMap[lang]['imprint']}>
            {t('footer.imprint')}
          </Link>
        </Box>
        <Box mx={1}>
          <Link style={{ color: '#636363' }} variant="body2" href={linkMap[lang]['privacyPolicy']}>
            {t('footer.privacyPolicy')}
          </Link>
        </Box>
        <Box mx={1}>
          <Link style={{ color: '#636363' }} variant="body2" href={linkMap[lang]['toc']}>
            {t('footer.toc')}
          </Link>
        </Box>
      </Box>
      <Box my={3}>
        <Typography variant="body2">© Flow Lab</Typography>
      </Box>
    </Box>
  );
}
