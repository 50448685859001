import _ from 'lodash';

import React from 'react';

import { createStyles, makeStyles } from '@material-ui/core/styles';

import {
  Typography,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Switch,
  FormControlLabel,
  Box,
  DialogTitle,
} from '@material-ui/core';

import { Session, Language } from 'types';

import TextField from './TextField';
import { DialogMode } from './types';

import { VariationRow } from './VariationRow';
import { SharableQuote } from './SharableQuote';
import { VariationStatus } from 'types/session';

const EMPTY_VARIATION = {
  id: null,
  fileId: null,
  duration: 0,
  introTimestamp: '00:00:00',
  outroTimestamp: '00:00:00',
  variationFile: null,
  status: VariationStatus.CREATED,
};

interface Props {
  isOpen: boolean;
  mode: DialogMode;
  onSubmit: (sessionProps: Session.Props) => void;
  onCancel: () => void;
  sessionProps: Session.Session;
}

const useStyles = makeStyles(() =>
  createStyles({
    languageSelectControl: {
      minWidth: 120,
    },
    weightsSelectControl: {
      minWidth: 80,
    },
    languageIntroRow: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    weightsRow: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    addButton: {
      marginLeft: 14,
    },
  })
);

function replaceAreaWeight(weights: Session.Weight[], areaId: number, newWeight: Session.WeightValue) {
  return weights.map((areaWeight: Session.Weight) => {
    if (areaWeight.flowArea === areaId) {
      return {
        flowArea: areaId,
        weight: newWeight,
      };
    } else {
      return areaWeight;
    }
  });
}

function inputToTimestamp(minutes: string, seconds: string) {
  return '00:' + minutes.padStart(2, '0') + ':' + seconds.padStart(2, '0');
}

interface WeightSelectProps {
  label: string;
  value: Session.WeightValue;
  onChange: (newValue: Session.WeightValue) => void;
  disabled: boolean;
}

function WeightSelect(props: WeightSelectProps) {
  const classes = useStyles();

  const _onChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newValue = parseInt(event.target.value as string) as Session.WeightValue;
    if (!isNaN(newValue)) {
      return props.onChange(newValue);
    }
  };

  return (
    <FormControl disabled={props.disabled} className={classes.weightsSelectControl}>
      <InputLabel id="weigth-input-label">{props.label}</InputLabel>
      <Select id="weigth-input-select" value={props.value} onChange={_onChange}>
        {_.range(11).map((weight: number) => {
          const _weight = weight as Session.WeightValue;
          return (
            <MenuItem key={weight} value={_weight}>
              {_weight.toString()}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

export function EditCreateSessionDialog(props: Props) {
  const classes = useStyles();
  const title = props.mode === DialogMode.CREATE ? 'Create Session' : 'Edit Session';

  const [sessionProps, setSessionProps] = React.useState(props.sessionProps);
  const isUnweighted = sessionProps.flowAreaWeights.length === 0;

  React.useEffect(() => {
    setSessionProps(props.sessionProps);
  }, [props]);

  const onLanguageChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSessionProps({
      ...sessionProps,
      language: event.target.value as Language,
    });
  };

  const onTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSessionProps({
      ...sessionProps,
      title: event.target.value,
    });
  };

  const onShortDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSessionProps({
      ...sessionProps,
      shortDescription: event.target.value,
    });
  };

  const onFullDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSessionProps({
      ...sessionProps,
      fullDescription: event.target.value,
    });
  };

  const onIsIntroChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSessionProps({
      ...sessionProps,
      isIntro: event.target.checked,
    });
  };

  const onIsFirstChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSessionProps({
      ...sessionProps,
      isFirst: event.target.checked,
    });
  };

  const onPaidOnlyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSessionProps({
      ...sessionProps,
      paidOnly: event.target.checked,
    });
  };

  const onAudioFileSelected = async (e: React.FormEvent<HTMLInputElement>, index: number) => {
    if (e.currentTarget != null && e.currentTarget.files) {
      const file = e.currentTarget.files[0];
      const newSessionProps = sessionProps;
      newSessionProps.variations[index].variationFile = file;

      setSessionProps({
        ...newSessionProps,
      });
    }
  };

  const onSharableQuoteSelected = async (e: React.FormEvent<HTMLInputElement>) => {
    if (e.currentTarget != null && e.currentTarget.files) {
      const file = e.currentTarget.files[0];
      const newSessionProps = sessionProps;
      newSessionProps.sharableQuote.file = file;

      setSessionProps({
        ...newSessionProps,
      });
    }
  };


  const onUnweightedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSessionProps({
        ...sessionProps,
        flowAreaWeights: [],
      });
    } else {
      setSessionProps({
        ...sessionProps,
        flowAreaWeights: _.range(1, 5).map((areaId: number) => {
          return {
            flowArea: areaId,
            weight: 0,
          };
        }),
      });
    }
  };

  const onDurationChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const duration = parseInt(event.target.value);

    if (!isNaN(duration)) {
      const newSessionProps = sessionProps;
      newSessionProps.variations[index].duration = duration;

      if (newSessionProps.variations[index].status !== VariationStatus.CREATED) {
        newSessionProps.variations[index].status = VariationStatus.EDITED;
      }

      setSessionProps({
        ...newSessionProps,
      });
    }
  };

  const onIntroTimestampChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const minSec = event.target.value.split(':');
    const newTimestamp = inputToTimestamp(minSec[0], minSec[1]);

    const newSessionProps = sessionProps;
    newSessionProps.variations[index].introTimestamp = newTimestamp;

    if (newSessionProps.variations[index].status !== VariationStatus.CREATED) {
      newSessionProps.variations[index].status = VariationStatus.EDITED;
    }

    setSessionProps({
      ...newSessionProps,
    });
  };

  const onOutroTimestampChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const minSec = event.target.value.split(':');
    const newTimestamp = inputToTimestamp(minSec[0], minSec[1]);

    const newSessionProps = sessionProps;
    newSessionProps.variations[index].outroTimestamp = newTimestamp;

    if (newSessionProps.variations[index].status !== VariationStatus.CREATED) {
      newSessionProps.variations[index].status = VariationStatus.EDITED;
    }

    setSessionProps({
      ...newSessionProps,
    });
  };

  const getFlowWeight = (areaId: number) => {
    const areaWeight = sessionProps.flowAreaWeights.find((weight: Session.Weight) => weight.flowArea === areaId);

    if (areaWeight) {
      return areaWeight.weight;
    } else {
      return 0;
    }
  };

  const onWeightChange = (areaId: number) => (newWeight: Session.WeightValue) => {
    if (sessionProps.flowAreaWeights) {
      setSessionProps({
        ...sessionProps,
        flowAreaWeights: replaceAreaWeight(sessionProps.flowAreaWeights, areaId, newWeight),
      });
    }
  };

  const onAddVariation = () => {
    const variations = sessionProps.variations;
    const emptyVariation = { ...EMPTY_VARIATION, session: sessionProps.id };
    setSessionProps({
      ...sessionProps,
      variations: [...variations, emptyVariation],
    });
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth="lg"
      open={props.isOpen}
      onClose={props.onCancel}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <TextField
          id="title"
          margin="normal"
          label="Title"
          value={sessionProps.title}
          onChange={onTitleChange}
          type="text"
          fullWidth
          required
          variant="outlined"
        />
        <TextField
          id="short-description"
          margin="normal"
          label="Short Description"
          value={sessionProps.shortDescription}
          onChange={onShortDescriptionChange}
          type="text"
          fullWidth
          multiline
          required
          rows="2"
          variant="outlined"
        />
        <TextField
          id="full-description"
          margin="normal"
          label="Full Description"
          value={sessionProps.fullDescription}
          onChange={onFullDescriptionChange}
          type="text"
          fullWidth
          multiline
          required
          rows="3"
          variant="outlined"
        />

        <Box className={classes.languageIntroRow} display="flex">
          <Box ml={3} my={1}>
            <FormControl className={classes.languageSelectControl}>
              <InputLabel id="language-input-label">Language</InputLabel>
              <Select id="language-select" value={sessionProps.language} onChange={onLanguageChange}>
                <MenuItem value={Language.DE}>German</MenuItem>
                <MenuItem value={Language.EN}>English</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box mr={3}>
            <FormControlLabel
              control={
                <Switch checked={sessionProps.isFirst} onChange={onIsFirstChange} value="isFirst" color="primary" />
              }
              label="First"
              labelPlacement="start"
            />
            <FormControlLabel
              control={
                <Switch checked={sessionProps.isIntro} onChange={onIsIntroChange} value="isIntro" color="primary" />
              }
              label="Intro"
              labelPlacement="start"
            />
            <FormControlLabel
              control={
                <Switch checked={sessionProps.paidOnly} onChange={onPaidOnlyChange} value="isIntro" color="primary" />
              }
              label="Paid Only"
              labelPlacement="start"
            />
          </Box>
        </Box>
        <Box my={1} className={classes.weightsRow} display="flex">
          <Box ml={2}>
            <Typography variant="h6">Weighting</Typography>
          </Box>
          <WeightSelect label="Focus" onChange={onWeightChange(1)} value={getFlowWeight(1)} disabled={isUnweighted} />
          <WeightSelect label="Ease" onChange={onWeightChange(2)} value={getFlowWeight(2)} disabled={isUnweighted} />
          <WeightSelect
            label="Optimism"
            onChange={onWeightChange(3)}
            value={getFlowWeight(3)}
            disabled={isUnweighted}
          />
          <WeightSelect label="Drive" onChange={onWeightChange(4)} value={getFlowWeight(4)} disabled={isUnweighted} />
          <Box mr={3}>
            <FormControlLabel
              control={
                <Switch checked={isUnweighted} onChange={onUnweightedChange} value="isUnweighted" color="primary" />
              }
              label="Unweighted"
              labelPlacement="start"
            />
          </Box>
        </Box>
        {props.mode === DialogMode.EDIT && (
          <>
            <Box border={1} borderRadius="borderRadius" pt={1} pb={1} mt={2}>
              {sessionProps.variations.map((variation: Session.Variation, index: number) => (
                <VariationRow
                  index={index}
                  variation={variation}
                  onDurationChange={onDurationChange}
                  onIntroTimestampChange={onIntroTimestampChange}
                  onOutroTimestampChange={onOutroTimestampChange}
                  onAudioFileSelected={onAudioFileSelected}
                  key={index}
                />
              ))}
              <Button onClick={onAddVariation} variant="contained" color="primary" className={classes.addButton}>
                Add variation
              </Button>
            </Box>
            <SharableQuote url={sessionProps.sharableQuote.url} onSelected={onSharableQuoteSelected}/>
          </>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={props.onCancel} color="primary">
          Cancel
        </Button>
        <Button
          onClick={() =>
            props.onSubmit({
              title: sessionProps.title,
              shortDescription: sessionProps.shortDescription,
              fullDescription: sessionProps.fullDescription,
              isIntro: sessionProps.isIntro,
              isFirst: sessionProps.isFirst,
              paidOnly: sessionProps.paidOnly,
              flowAreaWeights: sessionProps.flowAreaWeights,
              language: sessionProps.language as Language,
              variations: sessionProps.variations,
              sharableQuote: sessionProps.sharableQuote,
            })
          }
          color="primary"
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}
